import { loginUser, userInfoModify } from '@/api/user';
import VueCookies from "vue-cookies";
const userStore = {
	namespaced: true,
	state: {
		bnkCd: VueCookies.get("bnkCd") || "",
		bnkJcd: VueCookies.get("bnkJcd") || "",
		bnkJnm: VueCookies.get("bnkJnm") || "",
		bnkPerNm: VueCookies.get("bnkPerNm") || "",
		bnkTel: VueCookies.get("bnkTel") || "",
		bnkCell: VueCookies.get("bnkCell") || "",
		bnkMail: VueCookies.get("bnkMail") || "",
		pwExpireCheck: VueCookies.get("pwExpireCheck") || "",
		accessToken: VueCookies.get("accessToken") || "",
	},
	getters: {
		isLogin(state) {
			return state.accessToken !== "";
		},
		getBnkCd(state) {
			return state.bnkCd;
		},
		getBnkJcd(state) {
			return state.bnkJcd;
		},
		getBnkJnm(state) {
			return state.bnkJnm;
		},
		getBnkPerNm(state) {
			return state.bnkPerNm;
		},
		getBnkTel(state) {
			return state.bnkTel;
		},
		getBnkCell(state) {
			return state.bnkCell;
		},
		getBnkMail(state) {
			return state.bnkMail;
		},
		getPwExpireCheck(state) {
			return state.pwExpireCheck;
		},
		getAccessToken(state) {
			return state.accessToken;
		},
	},
	mutations: {
		SET_BNK_CD(state, bnkCd) {
			state.bnkCd = bnkCd;
		},
		SET_BNK_JCD(state, bnkJcd) {
			state.bnkJcd = bnkJcd;
		},
		SET_BNK_JNM(state, bnkJnm) {
			state.bnkJnm = bnkJnm;
		},
		SET_BNK_PER_NM(state, bnkPerNm) {
			state.bnkPerNm = bnkPerNm;
		},
		SET_BNK_TEL(state, bnkTel) {
			state.bnkTel = bnkTel;
		},
		SET_BNK_CELL(state, bnkCell) {
			state.bnkCell = bnkCell;
		},
		SET_BNK_MAIL(state, bnkMail) {
			state.bnkMail = bnkMail;
		},
		SET_PW_EXPIRE_CHECK(state, pwExpireCheck) {
			state.pwExpireCheck = pwExpireCheck;
		},
		SET_ACCESS_TOKEN(state, accessToken) {
			state.accessToken = accessToken;
		},
		CLEAR_ALL(state) {
			state.bnkCd = "";
			state.bnkJcd = "";
			state.bnkJnm = "";
			state.bnkPerNm = "";
			state.bnkTel = "";
			state.bnkCell = "";
			state.bnkMail = "";
			state.pwExpireCheck = "";
			state.accessToken = "";
		},
	},
	actions: {
		async LOGIN({ commit, dispatch }, loginUserData) {
			const { data } = await loginUser(loginUserData);
			dispatch("setCookies", data);
		},
		LOGOUT({ commit }) {
			commit("CLEAR_ALL");
			VueCookies.remove("bnkCd");
			VueCookies.remove("bnkJcd");
			VueCookies.remove("bnkJnm");
			VueCookies.remove("bnkPerNm");
			VueCookies.remove("bnkTel");
			VueCookies.remove("bnkCell");
			VueCookies.remove("bnkMail");
			VueCookies.remove("pwExpireCheck");
			VueCookies.remove("accessToken");
		},
		async userInfoModify({ commit, dispatch }, formData) {
			try {
				const { data } = await userInfoModify(formData);	
				if (data.message === "Success") {
					dispatch("setCookies", data);
				  	alert("저장하였습니다.");
				}
			}catch (error) {
				alert(error.response.data.message);
			}
		},
		setCookies({ commit }, data){
			commit("SET_BNK_CD", data.data.bnkCd);
			commit("SET_BNK_JCD", data.data.bnkJcd);
			commit("SET_BNK_JNM", data.data.bnkJnm);
			commit("SET_BNK_PER_NM", data.data.bnkPerNm);
			commit("SET_BNK_TEL", data.data.bnkTel);
			commit("SET_BNK_CELL", data.data.bnkCell);
			commit("SET_BNK_MAIL", data.data.bnkMail);
			commit("SET_PW_EXPIRE_CHECK", data.data.pwExpireCheck);
			commit("SET_ACCESS_TOKEN", data.data.accessToken);
			VueCookies.set("bnkCd", data.data.bnkCd);
			VueCookies.set("bnkJcd", data.data.bnkJcd);
			VueCookies.set("bnkJnm", data.data.bnkJnm || "");
			VueCookies.set("bnkPerNm", data.data.bnkPerNm || "");
			VueCookies.set("bnkTel", data.data.bnkTel || "");
			VueCookies.set("bnkCell", data.data.bnkCell || "");
			VueCookies.set("bnkMail", data.data.bnkMail || "");
			VueCookies.set("pwExpireCheck", data.data.pwExpireCheck || "");
			VueCookies.set("accessToken", data.data.accessToken || "");
		}
	},
};

export default userStore;
