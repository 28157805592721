<template>
  <div id="app" style="padding:10px">
    <!-- 로그인 후 -->
    <Header v-if="this.mount" :headerImage="headerImage"/>
    <table border="0" cellspacing="0" cellpadding="0" v-if="this.mount">
      <tr>
        <td width="235" valign="top" style="padding:12 0 0 0;">
          <SideBar v-if="this.mount" />
        </td>        
        <td valign="top">
          <div class="app__contents">
            <RouterView />
          </div>
        </td>
      </tr>
    </table>
    <Footer v-if="this.mount"/>
    <!-- 로그인 이전 -->
    <div class="app__contents" v-if="this.none">
      <RouterView />
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import SideBar from "@/components/common/SideBar.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    SideBar,
    Footer
  },
  computed: {
    mount() {
      return (
        this.$route.name !== "404" &&
        this.$route.name !== "BarIndex" &&
        this.$route.name !== "BarLoginMain" &&
        this.$route.name !== "NewChgPwd" &&
        this.$route.name !== "BarModypass"&&
        this.$route.name !== "ZipSearch"&&
        this.$route.name !== "BarReqView"
      );
    },
    none() {
      return (
        this.$route.name == "404" ||
        this.$route.name == "BarIndex" ||
        this.$route.name == "BarLoginMain" ||
        this.$route.name == "NewChgPwd" ||
        this.$route.name == "BarModypass"||
        this.$route.name == "ZipSearch"||
        this.$route.name == "BarReqView"
      );
    },
  },
  data() {
    return {
      headerImage : 'img_title1.gif'
    }
  },
  watch: {
    $route(to, from) {
      this.updateHeaderImage(to);
    }
  },
  methods: {
    updateHeaderImage(route) {
      if(route.name === 'BarService' || route.name === 'chgPw') {
        this.headerImage = 'img_title1.gif';
      }else if(route.name === 'BarReq') {
        this.headerImage = 'img_title2.gif';
      }else if(route.name === 'BarJReq') {
        this.headerImage = 'img_title3.gif';
      }else if(route.name === 'BarTReq') {
        this.headerImage = 'img_title3_1.gif';
      }else if(route.name === 'BarJResult') {
        this.headerImage = 'img_title4.gif';
      }else if(route.name === 'BarTResult') {
        this.headerImage = 'img_title4_1.gif';
      }else if(route.name === 'BarGongji' || route.name === 'BarGongjiView') {
        this.headerImage = 'img_title5.gif';
      }else if(route.name === 'BarPds' || route.name === 'BarPdsView') {
        this.headerImage = 'img_title6.gif';
      }else if(route.name === 'BarLink') {
        this.headerImage = 'img_title7.gif';
      }
    }
  },
};
</script>
<style scoped>
@import "./assets/css/cabank.css";
@import "./assets/css/glyphicon.css";
@import "./assets/css/temporary-password.css";
@import "./assets/css/reset.css";
</style>

