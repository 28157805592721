import { instance, instanceWithAuth } from '@/api/index';

function loginUser(userData) {
	return instance.post('/user/login', userData);
}

function newChgPwdAct(userData) {
	return instance.post('/user/newChgPwdAct', userData);
}

function userInfoModify(userData) {
	return instanceWithAuth.post('/user/userInfoModify', userData);
}

export { loginUser, newChgPwdAct, userInfoModify };
