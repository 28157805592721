import userStore from "@/store/modules/userStore";
import VueCookies from "vue-cookies";
import Swal from "sweetalert2";
import router from "@/router";

export function setInterseptors(instance) {
	instance.interceptors.request.use(
		function (config) {
			const token = userStore.state.accessToken;
			config.headers.Authorization = `Bearer ${token}`;
		
			return config;
		},
		function (error) {
			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(
		(res) => {
		  return res;
		},
		async (err) => {
		  const {
			config,
			response: { status },
		  } = err;
		  const originalRequest = config;
		  if (status === 401 && err.response.data.code === "A003") {
			VueCookies.remove("bnkCd");
			VueCookies.remove("bnkJcd");
			VueCookies.remove("bnkJnm");
			VueCookies.remove("bnkPerNm");
			VueCookies.remove("bnkTel");
			VueCookies.remove("bnkCell");
			VueCookies.remove("bnkMail");
			VueCookies.remove("pwExpireCheck");
			VueCookies.remove("accessToken");
			Swal.fire({
			  position: "center",
			  html: ` <div>
						<div>
						  <p>장시간 사용하지 않아 로그아웃 되었습니다.</p>
						</div>
					  </div>`,
			  showConfirmButton: false,
			  timer: 2500,
			});
			router.push("/");
		  }
		  else if (status === 401 && err.response.data.code === "A004") {
			VueCookies.remove("bnkCd");
			VueCookies.remove("bnkJcd");
			VueCookies.remove("bnkJnm");
			VueCookies.remove("bnkPerNm");
			VueCookies.remove("bnkTel");
			VueCookies.remove("bnkCell");
			VueCookies.remove("bnkMail");
			VueCookies.remove("pwExpireCheck");
			VueCookies.remove("accessToken");
			if (router.path !== "/user/login") {
			  router.push("/");
			}
		  }
		  return Promise.reject(err);
		  //새로운 요청 또한 실패했다면 Promise.reject(err)를 통해 error를 반환한다.
		}
	);

	return instance;
}
