import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import Swal from "sweetalert2";

const routes = [
  {
    path: "/",
    name: "BarIndex",
    component: () => import("@/views/BarIndex.vue"),
  },
  {
    path: "/BarLoginMain/:bnkCd",
    name: "BarLoginMain",
    component: () => import("@/views/BarLoginMain.vue"),
    props: true
  },
  {
    path: "/BarService",
    name: "BarService",
    component: () => import("@/views/BarService.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarReq",
    name: "BarReq",
    component: () => import("@/views/BarReq.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarJReq",
    name: "BarJReq",
    component: () => import("@/views/BarJReq.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarTReq",
    name: "BarTReq",
    component: () => import("@/views/BarTReq.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarJResult",
    name: "BarJResult",
    component: () => import("@/views/BarJResult.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarTResult",
    name: "BarTResult",
    component: () => import("@/views/BarTResult.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarGongji",
    name: "BarGongji",
    component: () => import("@/views/BarGongji.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarGongjiView/:sno",
    name: "BarGongjiView",
    component: () => import("@/views/BarGongjiView.vue"),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: "/BarPds",
    name: "BarPds",
    component: () => import("@/views/BarPds.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarPdsView/:sno",
    name: "BarPdsView",
    component: () => import("@/views/BarPdsView.vue"),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: "/BarLink",
    name: "BarLink",
    component: () => import("@/views/BarLink.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/ChgPw",
    name: "ChgPw",
    component: () => import("@/views/ChgPw.vue"),
    meta: { authRequired: true }
  },
  {
    path: "/BarModypass",
    name: "BarModypass",
    component: () => import("@/components/popup/BarModypass.vue"),
    meta: {
      authRequired: true, 
      css: ['./assets/css/reset.css', './assets/css/glyphicon.css', './assets/css/temporary-password']
    },
  },
  {
    path: "/NewChgPwd",
    name: "NewChgPwd",
    component: () => import("@/components/popup/NewChgPwd.vue"),
    meta: { css: ['./assets/css/reset.css', './assets/css/glyphicon.css', './assets/css/temporary-password'] },
  },
  {
    path: "/ZipSearch",
    name: "ZipSearch",
    component: () => import("@/components/popup/ZipSearch.vue"),
  },
  {
    path: "/BarReqView/:sno",
    name: "BarReqView",
    component: () => import("@/components/popup/BarReqView.vue"),
    props: true
  },
  // 예외
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let alertMessage = "";
  if (to.matched.some(routeInfo => routeInfo.meta.authRequired)) {
    const isLogin = store.getters["userStore/isLogin"];
    if (!isLogin) {
      alertMessage = "로그인 후 이용해 주세요.";
      next("/");
    }else {      
      next();
    }
  } else {
    next();
  }

  if (alertMessage) {
    Swal.fire({
      position: "center",
      html: ` <div>
                <div>
                  <p>${alertMessage}</p>
                </div>
              </div>`,
      showConfirmButton: false,
      timer: 1000,
    });

  }
});


export default router;
