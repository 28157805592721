<template>
<div id="header">
<table width="100%" border="0" cellspacing="0" cellpadding="0" height="120">
  <tr>
    <td width="235" :style="{ backgroundImage: 'url('+require('@/assets/images/top_logo_blank.gif')+')'}" valign="top"> 
      <table width="95%" border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td height="25" width="50">&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>		  
          <td><a href="" target="_top"><img :src="topLogoImageSrc()" width="160" height="50" border=0></a></td>
        </tr>
      </table>
    </td>
    <td :style="{ backgroundImage: 'url('+require('@/assets/images/top_bg.gif')+')'}"><img :src="headerImageSrc()" width="764" height="120"></td>
  </tr>
</table>
</div>    
</template>

<script>
export default {
  props: ['headerImage'],
  methods: {
		topLogoImageSrc() {
			return require('@/assets/images/top_logo_'+this.$store.getters["userStore/getBnkCd"]+'.gif');
		},    
    headerImageSrc() {
      return require('@/assets/images/'+this.headerImage);
    }
	},
};
</script>