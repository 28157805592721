<template>
<div>
<table width="186" border="0" cellspacing="0" cellpadding="0" height="66">
    <tr>
        <td height="63" :style="{ backgroundImage: 'url('+require('@/assets/images/login_bg.gif')+')'}"> 
        <table width="90%" border="0" cellspacing="0" cellpadding="0" align="center" v-if="isLogin">
            <tr> 
            <td rowspan=2 style="padding-left: 10px;"><img src="@/assets/images/arrow_3.gif" width="5" height="9"> <span color="39528D"><b>{{ getBnkJnm }}</b><br>
                &nbsp;&nbsp;&nbsp;담당자님<br>
                &nbsp;&nbsp;&nbsp;안녕하세요.</span></td>
            <td align="right" width="40%" height="28"><a href="javascript:;" @click="barLogout()"><img src="@/assets/images/btn_newlogout.gif" width="66" height="24"></a></td>
            </tr>
            <tr>                 
            <td align="right" width="40%" height="28"><a href="javascript:;" @click="goUrl('account')"><img src="@/assets/images/btn_newmodify.gif" width="66" height="24"></a></td>
            </tr>
        </table>
        </td>
    </tr>
</table>
<table width="186" border="0" cellspacing="0" cellpadding="0" height="10">
    <tr>
        <td></td>
    </tr>
</table>
<table width="186" border="0" cellspacing="0" cellpadding="0">
    <tr> 
        <td><img src="@/assets/images/left_mt.gif" width="186" height="34"></td>
    </tr>
    <tr>
        <td :style="{ backgroundImage: 'url('+require('@/assets/images/left_m_bg.gif')+')'}" align="center"> 
        <table width="85%" border="0" cellspacing="0" cellpadding="0">
            <tr> 
            <td height="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><a href="javascript:;" @click="goUrl('service')"><img src="@/assets/images/left_m1.gif" width="138" height="22" border="0" name="Image1" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image1','','/images/left_m1_o.gif',1)"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><a href="javascript:;" @click="goUrl('req')"><img src="@/assets/images/left_m2.gif" width="138" height="22" border="0" name="Image2" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image2','','/images/left_m2_o.gif',1)"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><img src="@/assets/images/left_m3.gif" width="138" height="22" border="0" name="Image3" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image3','','/images/left_m3_o.gif',1)"></td>
            </tr>
            <tr> 
            <td><a href="javascript:;"  @click="goUrl('j_req')"><img src="@/assets/images/left_m3_1.gif" width="89" height="11" border="0" hspace="10" vspace="2"></a></td>
            </tr>
            <tr> 
            <td><a href="javascript:;"  @click="goUrl('t_req')"><img src="@/assets/images/left_m3_2.gif" width="89" height="11" border="0" vspace="2" hspace="10"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><img src="@/assets/images/left_m4.gif" width="138" height="22" border="0" name="Image4" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image4','','/images/left_m4_o.gif',1)"></td>
            </tr>
            <tr>
            <td><a href="javascript:;" @click="goUrl('j_result')"><img src="@/assets/images/left_m4_1.gif" width="89" height="11" border="0" hspace="10" vspace="2"></a></td>
            </tr>
            <tr> 
            <td><a href="javascript:;" @click="goUrl('t_result')"><img src="@/assets/images/left_m4_2.gif" width="89" height="11" border="0" hspace="10" vspace="2"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><a href="javascript:;" @click="goUrl('gongJi')"><img src="@/assets/images/left_m5.gif" width="138" height="22" border="0" name="Image5" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image5','','/images/left_m5_o.gif',1)"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><a href="javascript:;" @click="goUrl('pds')"><img src="@/assets/images/left_m6.gif" width="138" height="22" border="0" name="Image6" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image6','','/images/left_m6_o.gif',1)"></a></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/left_m_line.gif" width="160" height="1" vspace="5"></td>
            </tr>
            <tr> 
            <td><img src="@/assets/images/arrow_4.gif" width="11" height="22"><a href="javascript:;" @click="goUrl('link')"><img src="@/assets/images/left_m7.gif" width="138" height="22" border="0" name="Image7" @mouseout="MM_swapImgRestore()" @mouseover="MM_swapImage('Image7','','/images/left_m7_o.gif',1)"></a></td>
            </tr>
        </table>
        </td>
    </tr>
    <tr> 
        <td><img src="@/assets/images/left_m_end.gif" width="186" height="18"></td>
    </tr>
    <tr> 
        <td style="padding-top:15px;"><img src="@/assets/images/ISMS-w186h77.png" alt="ISMS인증취득"></td>
    </tr>
</table>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("userStore", ["isLogin"]),
        ...mapGetters("userStore", ["getBnkJcd"]),
        ...mapGetters("userStore", ["getBnkJnm"]),
    },
  	methods: {
		topLogoImageSrc() {
			return require('@/assets/images/top_logo_'+this.$store.getters["userStore/getBnkCd"]+'.gif');
		},
        barLogout() {
            this.$store.dispatch("userStore/LOGOUT");
            alert("로그아웃 되었습니다.");
            this.$router.push("/");
        },
        goUrl(sMenu) {
            if (sMenu == 'service') { 
                this.$router.push("/BarService");
            }else if (sMenu == 'req') { 
                this.$router.push("/BarReq");
            }else if (sMenu == 'j_req') { 
                this.$router.push("/BarJReq");
            }else if (sMenu == 't_req') { 
                this.$router.push("/BarTReq");
            }else if (sMenu == 'j_result') { 
                this.$router.push("/BarJResult");
            }else if (sMenu == 't_result') { 
                this.$router.push("/BarTResult");
            }else if (sMenu == 'gongJi') { 
                this.$router.push("/BarGongji");
            }else if (sMenu == 'pds') { 
                this.$router.push("/BarPds");
            }else if (sMenu == 'link') { 
                this.$router.push("/BarLink");
            }else if (sMenu == 'account') { 
                window.open('/BarModypass', 'modiaccount', 'height=530px, width=460px, scrollbars=no');
            }
        },        
        MM_swapImgRestore() { //v3.0
            var i,x,a=document.MM_sr; 
            for(i=0;a&&i<a.length&&(x=a[i])&&x.oSrc;i++) x.src=x.oSrc;
        },
        MM_preloadImages() { //v3.0
            var d=document; if(d.images){ if(!d.MM_p) d.MM_p=new Array();
            var i,j=d.MM_p.length,a=MM_preloadImages.arguments; for(i=0; i<a.length; i++)
            if (a[i].indexOf("#")!=0){ d.MM_p[j]=new Image; d.MM_p[j++].src=a[i];}}
        },
        MM_findObj(n, d) { //v4.01
            var p,i,x;  if(!d) d=document; if((p=n.indexOf("?"))>0&&parent.frames.length) {
            d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
            if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
            for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
            if(!x && d.getElementById) x=d.getElementById(n); return x;
        },
        MM_swapImage(a1,a2,a3,a4) { //v3.0
            var i,j=0,x,a=[a1,a2,a3,a4];
            document.MM_sr=new Array;
            for(i=0;i<(a.length-2);i+=3)
            if ((x=this.MM_findObj(a[i]))!=null){
                document.MM_sr[j++]=x; if(!x.oSrc) x.oSrc=x.src; x.src=a[i+2];
            }
        }
	},
};
</script>